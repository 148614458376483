import { ScrollTrigger } from "gsap/ScrollTrigger";
//------------------------------------------------------//
// Slide menu 🎛️ toggle  
//------------------------------------------------------//
const navigationSlideToggle = document.querySelectorAll('.menu-toggle');
const navigationSlide = document.querySelector('#navigation-slide');
const body = document.querySelector('body');

// On 🐭 click add class to body and toggle 🦻 ARIA lables
navigationSlideToggle.forEach((item) => {
  item.addEventListener('click', () => {
    body.classList.toggle('showNavigationSlide');
    item.setAttribute('aria-expanded', item.getAttribute('aria-expanded') == 'true' ? 'false' : 'true');
  });
})

// END Slide menu 🎛️ toggle  -------------------------------------//

//------------------------------------------------------//
// On browser 📜 scroll full window height  
//------------------------------------------------------//
// const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

// window.onscroll = function (ev) {
//   if (window.scrollY >= width / 4) {
//     body.classList.add('showScrolled');
//   } else {
//     body.classList.remove('showScrolled');
//   }
// };

ScrollTrigger.create({
  id: "Scrolled",
  trigger: "body",
  start: "150px top",
  toggleClass: "showScrolled"
})



// END On browser 📜 scroll full window height  -------------------------------------//

