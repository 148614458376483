import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause",
  markers: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? true : false,
});

// END Setup 🧦 GSAP -------------------------------------//
//------------------------------------------------------//
// FAQ toggle 
//------------------------------------------------------//
const containerFaq = document.querySelectorAll('.overview-faq');

containerFaq.forEach(container => {
  const items = container.querySelectorAll('.alloy-item');
  items.forEach(item => {

    // The animation 
    const tl = gsap.timeline({ defaults: { duration: 0.3 }, reversed: true, paused: true })
    tl.set(item.querySelector('.hide'), { autoAlpha: 1 })

    tl.to(item.querySelector('.hide'), { height: "auto", })
    tl.to(item.querySelector('.icon'), { rotate: 180 }, "<")

    // The event listener
    item.addEventListener('click', e => {
      tl.reversed() ? tl.play() : tl.reverse();
    })
  });
});
// END FAQ toggle -------------------------------------//
//--------------------------------//
// Header logo effect 
//--------------------------------//
document.querySelectorAll("#main-header").forEach(function (container) {

  const item = container.querySelectorAll(".logo-effect");

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top top", // Can be top, center, bottom 
      end: "bottom top", // Can be top, center, bottom 
      // pin: true,
    }
  });

  timeline.to(item, {
    yPercent: -200,
  });

});
// END Header logo effect --------------//

//--------------------------------//
// ACF Berichten  
//--------------------------------//
document.querySelectorAll(".ACF-berichten").forEach(function (container) {

  const item = container.querySelectorAll("li");

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom", // Can be top, center, bottom 
      end: "bottom bottom", // Can be top, center, bottom 
      // pin: true,
    }
  });

  timeline.from(item, {
    scale: 0.8,
    stagger: 0.2,
  });

});
// END ACF Berichten  --------------//
//--------------------------------//
// Berichten archive
//--------------------------------//
const elms = [".page-template-template-berichten .grid > li", ".page-template-template-agenda .overview-agenda > li"]
gsap.set(elms, { scale: 0.7 })
ScrollTrigger.batch(elms, {
  onEnter: batch => gsap.to(batch, { scale: 1, duration: 0.2, stagger: 0.1 }),
  // onLeave: batch => gsap.to(batch, { scale: 0.7, duration: 0.2, stagger: 0.1 }),
  // onEnterBack: batch => gsap.to(batch, { scale: 1, duration: 0.2, stagger: 0.1 }),
  // onLeaveBack: batch => gsap.to(batch, { scale: 0.7, duration: 0.2, stagger: 0.1 }),
  start: "top bottom-=10%",
  end: "max",
  // markers: true,
});
// END Berichten archive  --------------//
//--------------------------------//
// 🧩 Split content 
//--------------------------------//
document.querySelectorAll(".ACF-split_content").forEach(function (container) {

  const item = container.querySelectorAll(".image img");

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=10%", // Can be top, center, bottom 
      end: "100% center", // Can be top, center, bottom 
      // pin: true,
    }
  });

  timeline.from(item, {
    scale: 1.2,
    ease: "none",
  });

});
// END 🧩 Split content --------------//
